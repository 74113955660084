// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onKey} from './github/onfocus'
import {validate} from './github/behaviors/html-validation'

on('typing:complete', '.js-signup-typed-welcome', function (event) {
  const typingEffectElement = event.target as HTMLElement
  typingEffectElement.click()
})

on('click', '[data-continue-to]', function ({currentTarget}) {
  const continueToTarget = document.getElementById(currentTarget.getAttribute('data-continue-to') || '')!
  continueToTarget.hidden = false
  setTimeout(() => {
    continueToTarget.querySelector<HTMLElement>('.js-continue-focus-target')!.focus()
  }, 0)
})

on('focusin', '.js-continue-focus-target', function ({currentTarget}) {
  const thisStepContainer = currentTarget.closest<HTMLElement>('.js-continue-step-container')!
  const container = currentTarget.closest<HTMLElement>('.js-continue-container')!
  const allStepContainers = container.querySelectorAll<HTMLElement>('.js-continue-step-container')!

  for (const stepContainer of allStepContainers) {
    if (stepContainer === thisStepContainer) {
      stepContainer.setAttribute('data-step-state', 'active')
    } else {
      const input = stepContainer.querySelector<HTMLInputElement>('.js-continue-input')

      if (input && !input.checkValidity()) {
        stepContainer.setAttribute('data-step-state', 'invalid')
      } else {
        stepContainer.setAttribute('data-step-state', 'complete')
      }
    }
  }

  const thisInput = thisStepContainer.querySelector<HTMLInputElement>('.js-continue-input')
  const hintContainer = container.querySelector<HTMLElement>('.js-continue-hint-container')!
  const allInputHintContainers = hintContainer.querySelectorAll<HTMLElement>('[data-hint-for]')

  if (thisInput && thisInput.value.trim()) {
    thisInput.dispatchEvent(new Event('input'))
  }

  for (const inputHintContainer of allInputHintContainers) {
    if (thisInput && thisInput.id === inputHintContainer.getAttribute('data-hint-for')) {
      inputHintContainer.hidden = false
      if (!thisInput.value.trim() && thisInput.required) resetInputValidity(thisInput)
    } else {
      inputHintContainer.hidden = true
    }
  }
})

onKey('keydown', '.js-continue-focus-target', function (event) {
  const focusTarget = event.currentTarget as HTMLElement
  const stepContainer = focusTarget.closest<HTMLElement>('.js-continue-step-container')!
  const button = stepContainer.querySelector<HTMLButtonElement>('.js-continue-button')
  const container = focusTarget.closest<HTMLElement>('.js-continue-container')!
  const allStepContainers = Array.from(container.querySelectorAll<HTMLElement>('.js-continue-step-container')!)
  const previousStepContainer = allStepContainers[allStepContainers.indexOf(stepContainer) - 1]

  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  switch (event.key) {
    case 'Enter':
      if (focusTarget.getAttribute('type') !== 'submit') {
        event.preventDefault()
      }
      button?.click()
      break
    case 'ArrowDown':
      button?.click()
      break
    case 'ArrowUp':
      previousStepContainer?.querySelector<HTMLElement>('.js-continue-focus-target')?.focus()
      break
  }
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

function updateInputValidity(input: HTMLInputElement, valid: boolean, hint: string): void {
  const stepContainer = input.closest<HTMLElement>('.js-continue-step-container')!
  const button = stepContainer.querySelector<HTMLButtonElement>('.js-continue-button')!
  const container = input.closest<HTMLElement>('.js-continue-container')!
  const hintContainer = container.querySelector<HTMLElement>('.js-continue-hint-container')!
  const inputHintContainer = hintContainer.querySelector<HTMLElement>(`[data-hint-for="${input.id}"`)!

  button.disabled = !valid
  inputHintContainer.innerHTML = hint
  input.setAttribute('aria-invalid', (!valid).toString())
}

on('focusout', '.signup-input', e => {
  const input = e.currentTarget as HTMLInputElement
  input.value = input.value.trim()
})

on('auto-check-success', '.js-continue-input', async function (event) {
  const input = event.currentTarget as HTMLInputElement
  const message = await event.detail.response.text()
  // This input.checkValidity() check is necessary for the email input because currently,
  // the browser email validation does not match the server email validation
  // This is a temporary accessibility fix until we can fix the server validation
  // https://github.com/github/accessibility-audits/issues/4733
  if (input.type === 'email' && !input.checkValidity()) {
    // eslint-disable-next-line i18n-text/no-en
    updateInputValidity(input, false, 'Email is invalid')
  } else {
    updateInputValidity(input, true, message)
  }
  validate(input.form!)
})

on('auto-check-error', '.js-continue-input', async function (event) {
  const input = event.currentTarget as HTMLInputElement
  const message = await event.detail.response.text()
  updateInputValidity(input, false, message)
  validate(input.form!)
})

function resetInputValidity(input: HTMLInputElement): void {
  const stepContainer = input.closest<HTMLElement>('.js-continue-step-container')!
  const button = stepContainer.querySelector<HTMLButtonElement>('.js-continue-button')!
  const container = input.closest<HTMLElement>('.js-continue-container')!
  const hintContainer = container.querySelector<HTMLElement>('.js-continue-hint-container')!
  const inputHintContainer = hintContainer.querySelector<HTMLElement>(`[data-hint-for="${input.id}"`)

  if (input.required) button.disabled = true
  if (inputHintContainer) inputHintContainer.textContent = ''
}

onKey('keyup', '.js-continue-input', function (event) {
  const input = event.currentTarget as HTMLInputElement
  if (!input.value.trim() && input.required) {
    resetInputValidity(input)
  }
})

observe('#captcha-and-submit-container', function (el) {
  setTimeout(() => {
    if (!(el instanceof HTMLElement)) return
    el.style.position = 'relative'
    el.hidden = true
    el.style.top = '0'
  }, 8000)
})

const isSlowConnection = () => {
  const connection = (navigator as Navigator & {connection: {effectiveType: string}}).connection

  return connection?.effectiveType.match(/2g/)
}
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
let warpStage: any

// only import animation module if we're on a connection that can support it
if (!isSlowConnection()) {
  ;(async () => {
    const {stageSetup} = await import('./github/webgl-warp')
    await new Promise(res => {
      requestAnimationFrame(res)
    })
    warpStage = stageSetup()

    const video: HTMLVideoElement | null = document.querySelector('.js-signup-warp-vid video')
    if (video) {
      video.setAttribute('preload', 'auto')
    }

    await new Promise(res => {
      requestAnimationFrame(res)
    })
    warpStage && warpStage.init()
  })()
}

const showAnimation = () => {
  const bg = document.querySelector('.js-signup-warp-bg')
  const vid = document.querySelector('.js-signup-warp-vid')
  const video: HTMLVideoElement | null = document.querySelector('.js-signup-warp-vid video')
  const desc = document.getElementById('video-desc')
  const fallbackImg = document.querySelector('.js-fallback-img')
  const toHide = document.querySelectorAll('.js-warp-hide')
  for (let i = 0; i < toHide.length; i++) {
    const el = toHide[i]
    el?.classList.add('warp-hide')
  }

  const skipWarpspeed =
    !warpStage || window.matchMedia('(prefers-reduced-motion: reduce)').matches || isSlowConnection()

  bg!.removeAttribute('hidden')
  desc!.removeAttribute('style') // trigger voiceover description

  if (skipWarpspeed) {
    video!.remove()
    fallbackImg?.removeAttribute('hidden')
    bg?.classList.add('skipwarp')
    vid?.classList.add('skipwarp')

    setTimeout(() => window.location.replace('/'), 4000)
  } else {
    warpStage!.hyperStart()
    bg!.classList.add('postwarp')
    vid!.classList.add('postwarp')

    setTimeout(() => warpStage!.hyperStop(), 2000)
    setTimeout(() => video!.play(), 2500)
    setTimeout(() => window.location.replace('/'), 6000)
  }
}

on('launch-code-success', 'launch-code', () => {
  if (window.location.search) {
    const return_to = new URLSearchParams(window.location.search).get('return_to')
    if (return_to) {
      try {
        const return_to_url = new URL(return_to, window.location.origin)
        if (return_to_url.pathname.startsWith('/github-copilot')) {
          window.location.replace(return_to_url.pathname + return_to_url.search)
          return
        }
      } catch {
        // If we can't parse the URL, ignore it and proceed with the animation
      }
    }
  }
  showAnimation()
})

on('click', '[data-launch-animation]', event => {
  event.preventDefault()
  window.scrollTo(0, 0)
  showAnimation()
})
